// WaGen___HASHKEY__95ba7012_2024-12-02 16:05:08 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
电子发票，生成用户电子发票。
*/
export function dzfpService_createInvoiceInfo(paramJson, meta) {
	return fetch({
		url : 'psdmsdfzw/service/DzfpService/createInvoiceInfo',
		method : 'post',
		headers : {
		},
		data : {
			param : paramJson //JSONObject
		}
	})
}

/* ---
电子发票，保存用户电子发票。
*/
export function dzfpService_saveInvoice(invoiceResult, meta) {
	return fetch({
		url : 'psdmsdfzw/service/DzfpService/saveInvoice',
		method : 'post',
		headers : {
		},
		data : {
			param : invoiceResult //JSONObject
		}
	})
}

/* ---
(乐企)电子发票，生成用户电子发票。
*/
export function dzfpService_createInvoiceInfoLeqi(paramJson, meta) {
	return fetch({
		url : 'psdmsdfzw/service/DzfpService/createInvoiceInfoLeqi',
		method : 'post',
		headers : {
		},
		data : {
			param : paramJson //JSONObject
		}
	})
}

/* ---
电子发票，获取用户电子发票信息。
*/
export function dzfpService_getInvoice(paramJson, meta) {
	return fetch({
		url : 'psdmsdfzw/service/DzfpService/getInvoice',
		method : 'post',
		headers : {
		},
		data : {
			param : paramJson //JSONObject
		}
	})
}

/* ---
电子发票，作废用户电子发票信息。
*/
export function dzfpService_invalidInvoice(paramJson, meta) {
	return fetch({
		url : 'psdmsdfzw/service/DzfpService/invalidInvoice',
		method : 'post',
		headers : {
		},
		data : {
			param : paramJson //JSONObject
		}
	})
}

/* ---
申请作废用户电子发票,审核通过后可发起作废
*/
export function dzfpService_invoiceZfsq(param, meta) {
	return fetch({
		url : 'psdmsdfzw/service/DzfpService/invoiceZfsq',
		method : 'post',
		headers : {
		},
		data : {
			param : param //JSONObject
		}
	})
}

/* ---
获取是否可以发起电子发票作废申请标志
*/
export function dzfpService_getDzfpZfsqFlag(jgbm,yhbh,zwyf,fphm, meta) {
	return fetch({
		url : 'psdmsdfzw/service/DzfpService/getDzfpZfsqFlag',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				yhbh : yhbh, //String
				zwyf : zwyf, //Integer
				fphm : fphm //String
			}
		}
	})
}

